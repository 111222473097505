import get from 'lodash/get';
import set from 'lodash/set';

import { pickAndRename } from 'utils/objectUtils';

import { replaceSettingsName } from './helpers';
import { ENDPOINTS, BE_FE_MAP, FE_BE_MAP } from './constants';

class ELink {
  constructor(restService) {
    this.restService = restService;
  }

  async getAll(params) {
    try {
      const response = await this.restService.get(
        `${ENDPOINTS.template}?${ENDPOINTS.getParams(params)}`,
      );

      return Promise.resolve(pickAndRename(response, BE_FE_MAP.getAll));
    } catch (error) {
      return this.#handleError(error);
    }
  }

  async getOne(uuid) {
    try {
      const { data } = await this.restService.get(
        `${ENDPOINTS.template}/${uuid}`,
      );
      const result = pickAndRename(data, BE_FE_MAP.getOne);

      return Promise.resolve({
        ...result,
        settings: result.settings.reduce(
          (memo, curr) => ({ ...memo, [curr.name]: { ...curr } }),
          {},
        ),
      });
    } catch (error) {
      return this.#handleError(error);
    }
  }

  async deleteOne(uuid) {
    try {
      await this.restService.delete(`${ENDPOINTS.template}/${uuid}`);
    } catch (error) {
      return this.#handleError(error);
    }
  }

  async deleteSetting(uuid) {
    try {
      await this.restService.delete(`elink-page-settings/${uuid}`);
    } catch (error) {
      return this.#handleError(error);
    }
  }

  async createTemplate(data) {
    try {
      return await this.restService.post(
        ENDPOINTS.template,
        pickAndRename(data, FE_BE_MAP.createTemplate),
      );
    } catch (error) {
      return this.#handleError(error);
    }
  }

  async updateTemplate(uuid, data) {
    try {
      return await this.restService.put(
        `${ENDPOINTS.template}/${uuid}`,
        pickAndRename(data, FE_BE_MAP.createTemplate),
      );
    } catch (error) {
      return this.#handleError(error);
    }
  }

  async getExternalPage(slug, config) {
    try {
      const { data } = await this.restService.get(
        ENDPOINTS.getExternalPage(slug),
        config,
      );
      const result = pickAndRename(data, BE_FE_MAP.getOne);

      return Promise.resolve({
        ...result,
        settings: result.settings.reduce(
          (memo, curr) => ({ ...memo, [curr.name]: { ...curr } }),
          {},
        ),
      });
    } catch (error) {
      return this.#handleError(error);
    }
  }

  async createLink({ uuid, ...params }) {
    try {
      const { data, meta } = await this.restService.post(
        ENDPOINTS.createLink(uuid),
        pickAndRename(params, FE_BE_MAP.createLink),
      );
      return Promise.resolve(pickAndRename({ ...data, ...meta }, BE_FE_MAP.createLink));
    } catch (error) {
      return this.#handleError(error);
    }
  }

  async getSlug(uuid, params) {
    try {
      const { slug } = await this.restService.post(
        ENDPOINTS.getSlug(uuid),
        pickAndRename(params, FE_BE_MAP.getSlug),
      );

      return slug;
    } catch (error) {
      return this.#handleError(error);
    }
  }

  async validateSlug(uuid, params) {
    try {
      const response = await this.restService.post(
        ENDPOINTS.validateSlug(uuid),
        pickAndRename(params, FE_BE_MAP.validateSlug),
      );

      return pickAndRename(response, BE_FE_MAP.validateSlug);
    } catch (error) {
      return this.#handleError(error);
    }
  }

  async getLinkTemplate(elinkPageUuid, linkTemplateUuid) {
    try {
      const { data } = await this.restService.get(
        ENDPOINTS.getLinkTemplate(elinkPageUuid, linkTemplateUuid),
      );

      return pickAndRename(data, BE_FE_MAP.getLinkTemplate);
    } catch (error) {
      return this.#handleError(error);
    }
  }

  async convertTypes({ sourceFormat = 'svg', targetFormat = 'eps', sourceData }) {
    try {
      const { data } = await this.restService.post(
        ENDPOINTS.convertTypes,
        pickAndRename({ sourceFormat, targetFormat, sourceData }, FE_BE_MAP.convertTypes),
      );

      return data;
    } catch (error) {
      return this.#handleError(error);
    }
  }

  async publish(uuid) {
    try {
      const { data } = await this.restService.patch(`${ENDPOINTS.template}/${uuid}/publish`);

      return pickAndRename(data, BE_FE_MAP.getOne);;
    } catch (error) {
      return this.#handleError(error);
    }
  }

  async unPublish(uuid) {
    try {
      const { data } = await this.restService.patch(`${ENDPOINTS.template}/${uuid}/unpublish`);

      return pickAndRename(data, BE_FE_MAP.getOne);;
    } catch (error) {
      return this.#handleError(error);
    }
  }

  #handleError(error) {
    const code = get(error, 'response.status');
    const errorsMessages = {};
    let errorMessage;

    if (code === 422) {
      const { settings } = JSON.parse(error.config.data);
      errorMessage = replaceSettingsName(
        null,
        get(error, 'response.data.message'),
        settings,
      );

      Object.entries(get(error, 'response.data.errors')).forEach(
        ([name, errors]) => {
          set(
            errorsMessages,
            ...replaceSettingsName(name, errors.join(' '), settings),
          );
        },
      );
    }

    return Promise.reject({ code, errorMessage, errors: errorsMessages });
  }
}

export default ELink;
